<template>
  <b-card>
    <form @submit.prevent="validateBeforeSubmit">
      <h1>Edit Password</h1>

      <p class="text-muted">Enter the current password and a new one</p>
      <b-row class="mb-3">
        <b-col md="12">
          <b-input-group class="mb-2">
            <b-input-group-prepend>
              <b-input-group-text><i class="fa fa-lock"></i></b-input-group-text>
            </b-input-group-prepend>
            <input class="form-control" v-model="oldPassword" v-validate="'required'" placeholder="Current Password"
              name="oldPassword" ref="oldPassword" :type="showOldPassword ? 'text' : 'password'" />
            <div class="input-group-append">
              <div :class="{ 'cursor-pointer': showOldPointer }" @click="showOldPassword = !showOldPassword"
                @mouseout="showOldPointer = false" @mouseover="showOldPointer = true" class="input-group-text">
                <i class="fa fa-eye" aria-hidden="true" v-if="!showOldPassword"></i>
                <i class="fa fa-eye-slash" aria-hidden="true" v-if="showOldPassword"></i>
              </div>
            </div>
          </b-input-group>
          <i v-show="errors.has('oldPassword')" class="mb-2 fa fa-exclamation-triangle"></i> <span
            v-show="errors.has('oldPassword')" class="help is-danger">{{ errors.first('oldPassword') }}</span>
        </b-col>
        <b-col md="12">
          <b-input-group class="mb-2">
            <b-input-group-prepend>
              <b-input-group-text><i class="fa fa-lock"></i></b-input-group-text>
            </b-input-group-prepend>
            <input class="form-control" v-model="newPassword" placeholder="New Password" data-vv-as="password"
              name="newPassword" ref="newPassword"
              v-validate="{ required: true, min: 14, regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/ }"
              :type="showNewPassword ? 'text' : 'password'" />
            <div class="input-group-append">
              <div :class="{ 'cursor-pointer': showNewPointer }" @click="showNewPassword = !showNewPassword"
                @mouseout="showNewPointer = false" @mouseover="showNewPointer = true" class="input-group-text">
                <i class="fa fa-eye" aria-hidden="true" v-if="!showNewPassword"></i>
                <i class="fa fa-eye-slash" aria-hidden="true" v-if="showNewPassword"></i>
              </div>
            </div>
          </b-input-group>
        </b-col>
        <b-col md="12">
          <b-input-group class="mb-2">
            <b-input-group-prepend>
              <b-input-group-text><i class="fa fa-lock"></i></b-input-group-text>
            </b-input-group-prepend>
            <input class="form-control" v-model="newPassword2" v-validate="'required|confirmed:newPassword|min:8'"
              placeholder="New Password Confirmation" data-vv-as="password" name="newPassword2"
              :type="showNewPassword2 ? 'text' : 'password'" />
            <div class="input-group-append">
              <div :class="{ 'cursor-pointer': showNewPointer2 }" @click="showNewPassword2 = !showNewPassword2"
                @mouseout="showNewPointer2 = false" @mouseover="showNewPointer2 = true" class="input-group-text">
                <i class="fa fa-eye" aria-hidden="true" v-if="!showNewPassword2"></i>
                <i class="fa fa-eye-slash" aria-hidden="true" v-if="showNewPassword2"></i>
              </div>
            </div>
          </b-input-group>
          <span v-show="errors.has('newPassword')">
            <h6 class="mt-2"><i class="fa fa-exclamation-triangle"></i> Your password must: </h6>
            <ol style="list-style-type: upper-greek;">
              <li>Include an UPPER and lowercase letter.</li>
              <li>Include a number.</li>
              <li>Include one or more of these special characters: .@$!%*#?&gt&lt&quot)(^-_ </li>
              <li>At least 14 characters</li>
            </ol>
          </span>
          <i v-show="errors.has('newPassword2')" class="mb-2 fa fa-exclamation-triangle"></i> <span
            v-show="errors.has('newPassword2')" class="help is-danger">{{ errors.first('newPassword2') }}</span>
        </b-col>
      </b-row>
      <i v-show="passworderror" class="fa fa-exclamation-triangle"></i><span v-show="passworderror"
        class="help is-danger"> {{ passwordErrorMsg }}</span>

      <b-button variant="success" class="mt-4" type="submit" block>Edit Password</b-button>
    </form>
  </b-card>
</template>

<script>
export default {
  name: 'AdminEditProfile',
  data: function () {
    return {
      oldPassword: '',
      newPassword: '',
      newPassword2: '',
      passworderror: false,
      showOldPassword: false,
      showOldPointer: false,
      showNewPassword: false,
      showNewPointer: false,
      showNewPassword2: false,
      showNewPointer2: false,
      passwordErrorMsg: '',
    }
  },
  methods: {
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = JSON.stringify({
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
            newPasswordConfirm: this.newPassword2
          })
          this.$http.post('/wts/edit/password', data)
            .then(response => {
              if (response.status === 200) {
                this.$session.set('jwt', response.data.session)
                this.$toasted.show('Password Updated', { icon: 'fa-check', type: 'success', duration: '3000' })
                this.$router.go(-1)
                this.passworderror = false;
              }
            })
            .catch(error => {
              if (error.response.data.code == 'PASSWORD_MISMATCH') {
                this.passwordErrorMsg = "The passwords must match."
              } else if (error.response.data.code == 'INCORRECT_PASSWORD') {
                this.passwordErrorMsg = "The old passwords is incorrect."
              } else if (error.response.data.code == 'USED_PASSWORD') {
                this.passwordErrorMsg = "The password has been used in last 24 months. please use new password"
              } else if (error.response.data.code == 'WEAK_PASSWORD') {
                this.passwordErrorMsg = "This password is weak. Please try another password."
              } else {
                this.passwordErrorMsg = "Error, Try again."
              }
              this.passworderror = true;
            })
          return;
        }
      });
    }
  }
}
</script>