var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-card", [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.validateBeforeSubmit($event)
          }
        }
      },
      [
        _c("h1", [_vm._v("Edit Password")]),
        _c("p", { staticClass: "text-muted" }, [
          _vm._v("Enter the current password and a new one")
        ]),
        _c(
          "b-row",
          { staticClass: "mb-3" },
          [
            _c(
              "b-col",
              { attrs: { md: "12" } },
              [
                _c(
                  "b-input-group",
                  { staticClass: "mb-2" },
                  [
                    _c(
                      "b-input-group-prepend",
                      [
                        _c("b-input-group-text", [
                          _c("i", { staticClass: "fa fa-lock" })
                        ])
                      ],
                      1
                    ),
                    (_vm.showOldPassword ? "text" : "password") === "checkbox"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.oldPassword,
                              expression: "oldPassword"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          ref: "oldPassword",
                          staticClass: "form-control",
                          attrs: {
                            placeholder: "Current Password",
                            name: "oldPassword",
                            type: "checkbox"
                          },
                          domProps: {
                            checked: Array.isArray(_vm.oldPassword)
                              ? _vm._i(_vm.oldPassword, null) > -1
                              : _vm.oldPassword
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.oldPassword,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.oldPassword = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.oldPassword = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.oldPassword = $$c
                              }
                            }
                          }
                        })
                      : (_vm.showOldPassword ? "text" : "password") === "radio"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.oldPassword,
                              expression: "oldPassword"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          ref: "oldPassword",
                          staticClass: "form-control",
                          attrs: {
                            placeholder: "Current Password",
                            name: "oldPassword",
                            type: "radio"
                          },
                          domProps: { checked: _vm._q(_vm.oldPassword, null) },
                          on: {
                            change: function($event) {
                              _vm.oldPassword = null
                            }
                          }
                        })
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.oldPassword,
                              expression: "oldPassword"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          ref: "oldPassword",
                          staticClass: "form-control",
                          attrs: {
                            placeholder: "Current Password",
                            name: "oldPassword",
                            type: _vm.showOldPassword ? "text" : "password"
                          },
                          domProps: { value: _vm.oldPassword },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.oldPassword = $event.target.value
                            }
                          }
                        }),
                    _c("div", { staticClass: "input-group-append" }, [
                      _c(
                        "div",
                        {
                          staticClass: "input-group-text",
                          class: { "cursor-pointer": _vm.showOldPointer },
                          on: {
                            click: function($event) {
                              _vm.showOldPassword = !_vm.showOldPassword
                            },
                            mouseout: function($event) {
                              _vm.showOldPointer = false
                            },
                            mouseover: function($event) {
                              _vm.showOldPointer = true
                            }
                          }
                        },
                        [
                          !_vm.showOldPassword
                            ? _c("i", {
                                staticClass: "fa fa-eye",
                                attrs: { "aria-hidden": "true" }
                              })
                            : _vm._e(),
                          _vm.showOldPassword
                            ? _c("i", {
                                staticClass: "fa fa-eye-slash",
                                attrs: { "aria-hidden": "true" }
                              })
                            : _vm._e()
                        ]
                      )
                    ])
                  ],
                  1
                ),
                _c("i", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("oldPassword"),
                      expression: "errors.has('oldPassword')"
                    }
                  ],
                  staticClass: "mb-2 fa fa-exclamation-triangle"
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("oldPassword"),
                        expression: "errors.has('oldPassword')"
                      }
                    ],
                    staticClass: "help is-danger"
                  },
                  [_vm._v(_vm._s(_vm.errors.first("oldPassword")))]
                )
              ],
              1
            ),
            _c(
              "b-col",
              { attrs: { md: "12" } },
              [
                _c(
                  "b-input-group",
                  { staticClass: "mb-2" },
                  [
                    _c(
                      "b-input-group-prepend",
                      [
                        _c("b-input-group-text", [
                          _c("i", { staticClass: "fa fa-lock" })
                        ])
                      ],
                      1
                    ),
                    (_vm.showNewPassword ? "text" : "password") === "checkbox"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newPassword,
                              expression: "newPassword"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                required: true,
                                min: 14,
                                regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/
                              },
                              expression:
                                "{ required: true, min: 14, regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/ }"
                            }
                          ],
                          ref: "newPassword",
                          staticClass: "form-control",
                          attrs: {
                            placeholder: "New Password",
                            "data-vv-as": "password",
                            name: "newPassword",
                            type: "checkbox"
                          },
                          domProps: {
                            checked: Array.isArray(_vm.newPassword)
                              ? _vm._i(_vm.newPassword, null) > -1
                              : _vm.newPassword
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.newPassword,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.newPassword = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.newPassword = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.newPassword = $$c
                              }
                            }
                          }
                        })
                      : (_vm.showNewPassword ? "text" : "password") === "radio"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newPassword,
                              expression: "newPassword"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                required: true,
                                min: 14,
                                regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/
                              },
                              expression:
                                "{ required: true, min: 14, regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/ }"
                            }
                          ],
                          ref: "newPassword",
                          staticClass: "form-control",
                          attrs: {
                            placeholder: "New Password",
                            "data-vv-as": "password",
                            name: "newPassword",
                            type: "radio"
                          },
                          domProps: { checked: _vm._q(_vm.newPassword, null) },
                          on: {
                            change: function($event) {
                              _vm.newPassword = null
                            }
                          }
                        })
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newPassword,
                              expression: "newPassword"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                required: true,
                                min: 14,
                                regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/
                              },
                              expression:
                                "{ required: true, min: 14, regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/ }"
                            }
                          ],
                          ref: "newPassword",
                          staticClass: "form-control",
                          attrs: {
                            placeholder: "New Password",
                            "data-vv-as": "password",
                            name: "newPassword",
                            type: _vm.showNewPassword ? "text" : "password"
                          },
                          domProps: { value: _vm.newPassword },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.newPassword = $event.target.value
                            }
                          }
                        }),
                    _c("div", { staticClass: "input-group-append" }, [
                      _c(
                        "div",
                        {
                          staticClass: "input-group-text",
                          class: { "cursor-pointer": _vm.showNewPointer },
                          on: {
                            click: function($event) {
                              _vm.showNewPassword = !_vm.showNewPassword
                            },
                            mouseout: function($event) {
                              _vm.showNewPointer = false
                            },
                            mouseover: function($event) {
                              _vm.showNewPointer = true
                            }
                          }
                        },
                        [
                          !_vm.showNewPassword
                            ? _c("i", {
                                staticClass: "fa fa-eye",
                                attrs: { "aria-hidden": "true" }
                              })
                            : _vm._e(),
                          _vm.showNewPassword
                            ? _c("i", {
                                staticClass: "fa fa-eye-slash",
                                attrs: { "aria-hidden": "true" }
                              })
                            : _vm._e()
                        ]
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-col",
              { attrs: { md: "12" } },
              [
                _c(
                  "b-input-group",
                  { staticClass: "mb-2" },
                  [
                    _c(
                      "b-input-group-prepend",
                      [
                        _c("b-input-group-text", [
                          _c("i", { staticClass: "fa fa-lock" })
                        ])
                      ],
                      1
                    ),
                    (_vm.showNewPassword2 ? "text" : "password") === "checkbox"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newPassword2,
                              expression: "newPassword2"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|confirmed:newPassword|min:8",
                              expression:
                                "'required|confirmed:newPassword|min:8'"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            placeholder: "New Password Confirmation",
                            "data-vv-as": "password",
                            name: "newPassword2",
                            type: "checkbox"
                          },
                          domProps: {
                            checked: Array.isArray(_vm.newPassword2)
                              ? _vm._i(_vm.newPassword2, null) > -1
                              : _vm.newPassword2
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.newPassword2,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.newPassword2 = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.newPassword2 = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.newPassword2 = $$c
                              }
                            }
                          }
                        })
                      : (_vm.showNewPassword2 ? "text" : "password") === "radio"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newPassword2,
                              expression: "newPassword2"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|confirmed:newPassword|min:8",
                              expression:
                                "'required|confirmed:newPassword|min:8'"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            placeholder: "New Password Confirmation",
                            "data-vv-as": "password",
                            name: "newPassword2",
                            type: "radio"
                          },
                          domProps: { checked: _vm._q(_vm.newPassword2, null) },
                          on: {
                            change: function($event) {
                              _vm.newPassword2 = null
                            }
                          }
                        })
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newPassword2,
                              expression: "newPassword2"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|confirmed:newPassword|min:8",
                              expression:
                                "'required|confirmed:newPassword|min:8'"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            placeholder: "New Password Confirmation",
                            "data-vv-as": "password",
                            name: "newPassword2",
                            type: _vm.showNewPassword2 ? "text" : "password"
                          },
                          domProps: { value: _vm.newPassword2 },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.newPassword2 = $event.target.value
                            }
                          }
                        }),
                    _c("div", { staticClass: "input-group-append" }, [
                      _c(
                        "div",
                        {
                          staticClass: "input-group-text",
                          class: { "cursor-pointer": _vm.showNewPointer2 },
                          on: {
                            click: function($event) {
                              _vm.showNewPassword2 = !_vm.showNewPassword2
                            },
                            mouseout: function($event) {
                              _vm.showNewPointer2 = false
                            },
                            mouseover: function($event) {
                              _vm.showNewPointer2 = true
                            }
                          }
                        },
                        [
                          !_vm.showNewPassword2
                            ? _c("i", {
                                staticClass: "fa fa-eye",
                                attrs: { "aria-hidden": "true" }
                              })
                            : _vm._e(),
                          _vm.showNewPassword2
                            ? _c("i", {
                                staticClass: "fa fa-eye-slash",
                                attrs: { "aria-hidden": "true" }
                              })
                            : _vm._e()
                        ]
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("newPassword"),
                        expression: "errors.has('newPassword')"
                      }
                    ]
                  },
                  [
                    _c("h6", { staticClass: "mt-2" }, [
                      _c("i", { staticClass: "fa fa-exclamation-triangle" }),
                      _vm._v(" Your password must: ")
                    ]),
                    _c(
                      "ol",
                      { staticStyle: { "list-style-type": "upper-greek" } },
                      [
                        _c("li", [
                          _vm._v("Include an UPPER and lowercase letter.")
                        ]),
                        _c("li", [_vm._v("Include a number.")]),
                        _c("li", [
                          _vm._v(
                            'Include one or more of these special characters: .@$!%*#?><")(^-_ '
                          )
                        ]),
                        _c("li", [_vm._v("At least 14 characters")])
                      ]
                    )
                  ]
                ),
                _c("i", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("newPassword2"),
                      expression: "errors.has('newPassword2')"
                    }
                  ],
                  staticClass: "mb-2 fa fa-exclamation-triangle"
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("newPassword2"),
                        expression: "errors.has('newPassword2')"
                      }
                    ],
                    staticClass: "help is-danger"
                  },
                  [_vm._v(_vm._s(_vm.errors.first("newPassword2")))]
                )
              ],
              1
            )
          ],
          1
        ),
        _c("i", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.passworderror,
              expression: "passworderror"
            }
          ],
          staticClass: "fa fa-exclamation-triangle"
        }),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.passworderror,
                expression: "passworderror"
              }
            ],
            staticClass: "help is-danger"
          },
          [_vm._v(" " + _vm._s(_vm.passwordErrorMsg))]
        ),
        _c(
          "b-button",
          {
            staticClass: "mt-4",
            attrs: { variant: "success", type: "submit", block: "" }
          },
          [_vm._v("Edit Password")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }